import axios from "axios"
import api from "./interceptorServices"
import { async } from "q"

const API_URL = "https://azcool2.net/api/"
// const API_URL = "http://localhost:8000/api/"

const SaveBets = async (body) => {
  try {
    const { data } = await api.post(`${API_URL}bet/add`, body)
    return data
  } catch (error) {
    console.log(error)
  }
}

const HotNum = async () => {
  try {
    const { data } = await api.get(`${API_URL}hotnumbers/getdata`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const KeyCode = async () => {
  try {
    const { data } = await api.get(`${API_URL}keycode/getdata`)
    return data
  } catch (error) {
    console.log(error)
  }
}

export { SaveBets, HotNum, KeyCode }
